// Static configs
const BACKEND_API = process.env.REACT_APP_BACKEND_API;
const HARDHAT_LOCAL_URL = process.env.REACT_APP_HARDHAT_LOCAL_URL;
const SCAN_SITE_URL = process.env.REACT_APP_SCAN_SITE_URL;
const AUTH_HEARTBEAT_EACH_SECONDS = 60 * 9; // 9 minutes

export {
    BACKEND_API,
    HARDHAT_LOCAL_URL,
    SCAN_SITE_URL,
    AUTH_HEARTBEAT_EACH_SECONDS,
};