import './App.scss';
import React from "react";
import {Route, Routes} from 'react-router-dom';

import Header from "./components/UI/Header/Header";
import Main from "./pages/Main/Main";
import About from "./pages/About/About";
import Info from "./pages/Info/Info";
import './scss/app.scss'

const App = () => {
  return (
      <>
        <Header/>
        <Routes>
          <Route path={'/'} element={<Main/>}/>
          {/*<Route path={'/about'} element={<About/>}/>*/}
          {/*<Route path={'/faq'} element={<Info/>}/>*/}
        </Routes>
      </>)
};

export default App;
