import React from 'react';

const Metamask = ({etheriumConfiguration}) => {
    if (!etheriumConfiguration) {
        return;
    }

    if (etheriumConfiguration.isMetamaskInstalled) {
        return (<div>Current account: {etheriumConfiguration.currentAccount}</div>)
    }
    return (<div>Please install metamask</div>)
};

export default Metamask;