import React from 'react';
import classes from './Header.module.scss'
import { Link } from "react-router-dom";
import {Network, NetworkOk} from "../Network/Network";
import Metamask from "../Metamask/Metamask";
import { useSelector } from "react-redux";


const Header = () => {
    const backendConfig = useSelector((state) => state.configuration.config);
    const etheriumConfiguration = useSelector((state) => state.etherium);

    return (
        <header className={classes.Header}>
            <div className="container">
                <div className={classes.header_container}>
                    <Link to={'/'} className={classes.logo}>Web3Game</Link>
                    {/* <nav className={classes.navigation}>
                        <ul>
                            <li><Link to={'/about'}>About</Link></li>
                            <li><Link to={'/faq'}>FAQ</Link></li>
                        </ul>
                    </nav> */}
                    <div className={classes.info}>
                        <Metamask etheriumConfiguration={etheriumConfiguration} />
                        <Network
                            backendConfig={backendConfig}
                            etheriumConfiguration={etheriumConfiguration}
                        />
                        <NetworkOk
                            backendConfig={backendConfig}
                            etheriumConfiguration={etheriumConfiguration}
                        />
                    </div>
                </div>

            </div>
        </header>
    );
};

export default Header;