
import { configureStore } from '@reduxjs/toolkit';
import configurationReducer from './slices/config.slice';
import etheriumReducer from './slices/etherium.slice';
import eventsReducer from './slices/events.slice';
import playtokenReducer from './slices/playtoken.slice';

const store = configureStore({
    reducer: {
        configuration: configurationReducer,
        etherium: etheriumReducer,
        events: eventsReducer,
        playtoken: playtokenReducer,
    },
    preloadedState:loadFromLocalStorage(),
});


function saveToLocalStorage(state) {
    try {
        const serialState = JSON.stringify(state)
        localStorage.setItem("reduxStore", serialState)
    } catch (e) {
        console.warn(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serialisedState = localStorage.getItem("reduxStore");
        if (serialisedState === null) return undefined;
        return JSON.parse(serialisedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

store.subscribe(()=>saveToLocalStorage(store.getState()));
export default store;