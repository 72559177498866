import { Buffer } from 'buffer';

const itemKey = "qp"

export function saveQueryParams(searchParams, setSearchParams) {
    const queryParams = searchParams.toString();
    if (queryParams.length == 0) {
        return
    }

    var buffQueryParams = Buffer.from(queryParams)
    var queryParamsBase64 = buffQueryParams.toString('base64');

    const now = new Date()
    const day = 24 * 60 * 60 * 1000;

    const result = JSON.stringify({
        q: queryParamsBase64,
        expiry: now.getTime() + day,
    })

    // Set query only if it was not set before
    if (fetchQueryParams()) {
        return
    }
    localStorage.setItem(itemKey, result)
    // Clear query params
    setSearchParams(new URLSearchParams(''));
}

export function fetchQueryParams() {
    let queryParams = "";
    try {
        let storageQueryParams = localStorage.getItem(itemKey);
        if (storageQueryParams) {
            const now = new Date();
            const queryParamsObj = JSON.parse(storageQueryParams);
            if (now.getTime() > queryParamsObj.expiry) {
                localStorage.removeItem(itemKey)
            }
            queryParams = queryParamsObj.q;
        }
    } catch(e) {
        console.log(e);
    }
    return queryParams
}