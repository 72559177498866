import React from 'react';
import classes from './CommonButton.module.scss';

const CommonButton = ({onClick, children, style, title}) => {
    return (
        <button style={style} className={classes.CommonButton} onClick={onClick} title={title}>
            {children}
        </button>
    );
};

export default CommonButton;